import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MostWantedModel from '../../models/MostWantedModel';
import MostWantedService from '../../services/api/MostWanted';

import Util from '../../utils/Util';
import { NameSpace } from '../../data/AppLanguage';

import { useTranslation } from 'react-i18next';
import { Chip, Container, Divider, Grid, Typography, Paper } from '@mui/material';

import { experimentalStyled as styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    maxWidth: 400,
  }));


const MostWanted = () => {
    const { t, i18n } = useTranslation([NameSpace.COMMON, NameSpace.ROUTES]);
    const [mostWanteds, setMostWanteds] = useState<MostWantedModel[]>();
    const language = Util.splitLanguage(i18n.language, 0);
    const productFinalPath = t("productFinalItem", {        
        ns: NameSpace.ROUTES,
      });
    
      useEffect(() => {
        MostWantedService.getMostwanteds(language).then((res) => {
            setMostWanteds(res.data);
            console.log(res.data);
        })
      },[])
    
      const buildUrl = (ancestors: string[]) => {
        return `/${language}/${ancestors?.slice()?.join("/")}`;
      };

    return (        
        <Container maxWidth={'xl'} justifyContent={'center'} sx={{ mb: 10}}>       
            <Divider  sx={{ mb: 10, mt: 5}}>
                <Chip sx={{ fontSize: 25, fontWeight: 500, textTransform: 'uppercase', color: 'rgb(64, 67, 70)', backgroundColor: '#f2f2f2'}} label={t("mostWanted", {ns : NameSpace.NAVIGATION})} size="medium" />
            </Divider>             
            <Grid container spacing={{ xs: 3, md: 3 }} columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} justifyContent={'center'} >
                {mostWanteds?.map((dataSheet, index) => {

                    const ancestorsPaths  = dataSheet.ancestors?.map(item => item.path) || [];                    
                    const url = buildUrl(ancestorsPaths);                  

                    return(
                        <Grid item 
                        key={index}
                        xs={12}
                        md={4}                      
                        lg={3}
                        xl={3}
                        >
                            <Link to={`${url}/${dataSheet.dataSheetId}`}>
                                <Item>
                                    <img src={dataSheet.urlImageMain ?? ""} style={{ maxWidth: '100%'}}/>
                                    <Typography component={'h1'} variant='body1'>
                                        {dataSheet.title ?? ""}
                                    </Typography>
                                    <Typography component={'h1'} variant='body2' fontSize={10}>
                                        {dataSheet.subtitle ?? ""}
                                    </Typography>
                                    {/* <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} /> */}
                                </Item>
                            </Link>                         
                        </Grid>
                    );
                })} 
            </Grid>
        </Container>
    );
};

export default MostWanted;

import { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

import { ContentPageModel } from "../../models/ContentPageModel";
import { LocationModel } from "../../models/LocationModel";

import { NameSpace, contactForm } from "../../data/AppLanguage";
import { ContentPageType } from "../../enums/ContentPageType";

import Util from "../../utils/Util";

import ContentPageService from "../../services/api/ContentPage";
import ContactService from "../../services/api/Contact";

import MainTitle from "../../components/MainTitle/MainTitle";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Title from "../../components/Title/Title";
import Description from "../../components/Description/Description";
import LocationCollection from "../../components/LocationCollection/LocationCollection";
import ContactLocationForm from "../../components/ContactForm/ContactForm";
import GoogleMapMarker from "../../components/GoogleMapMarker/GoogleMapMarker";

import "./Contact.css";

const center = {
  lat: 39.78836146107875,
  lng: -7.986022052256114,
};

const Contact = () => {
  const [contact, setContact] = useState<ContentPageModel>();
  const [locations, setLocations] = useState<LocationModel[]>([]);
  const [selected, setSelected] = useState<LocationModel>();
  const [currentLocation, setCurrentLocation] = useState<LocationModel | null>(
    null
  );
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);

  const { t, i18n } = useTranslation([
    NameSpace.CONTACTFORMS,
    NameSpace.NAVIGATION,
    NameSpace.PAGES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);
  const contactTitle = t("contact", { ns: NameSpace.NAVIGATION });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.title = Util.setDocumentTitle(contactTitle);

      ContentPageService.getContentPages(
        ContentPageType.CONTACT,
        language
      ).then((res) => {
        setContact(res.data);
      });

      ContactService.getLocations(language).then((res) => {
        setLocations(res.data);
      });
    }

    return () => {
      mounted = false;
    };
  }, [contactTitle, language]);

  return (
    <>
      <MainTitle media={contact?.media} name={contactTitle} height="70vh" />
      <BreadcrumbPath current="Contactos" />
      <article className="contact mb-8">
        <Container>
          {contact?.title ? (
            <Row>
              <Col className="text-center">
                <Title title={contact.title} className="title-title-contact" />
              </Col>
            </Row>
          ) : null}
          <Row className="mt-7">
            <Col xs={12} md={6}>
              {contact?.subtitle ? (
                <Row>
                  <Col>
                    <Title
                      title={contact.subtitle}
                      className="title-subtitle-contact"
                    />
                  </Col>
                </Row>
              ) : null}
              {contact?.body ? (
                <Row>
                  <Col>
                    <Description
                      text={contact.body}
                      className="description-contact"
                    />
                  </Col>
                </Row>
              ) : null}
              {locations && locations.length > 0 ? (
                <LocationCollection locations={locations} />
              ) : null}
            </Col>
            <Col xs={12} md={6}>
              <ContactLocationForm className="contact-contact-form" />
              {locations ? (
                <>
                  <Row className="mt-10">
                    <Col className="contact-map-title">
                      {t("map", { ns: NameSpace.PAGES })}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <LoadScript googleMapsApiKey="AIzaSyDqU5Ed-mSSIij99-K6wn9WhgDVdWa2cys">
                        {/* <GoogleMap
                          onLoad={(map) => setMapRef(map)}
                          mapContainerClassName="contact-map"
                          center={
                            selected
                              ? {
                                  lat: selected.latitude,
                                  lng: selected.longitude,
                                }
                              : center
                          }
                          zoom={6}
                        >
                          {locations.map((item, index) => {
                            return (
                              <GoogleMapMarker
                                location={item}
                                locations={locations}
                                currentLocation={currentLocation}
                                setCurrentLocation={setCurrentLocation}
                                index={index}
                                key={index}
                              />
                            );
                          })}
                        </GoogleMap> */}
                        <iframe 
                        allowFullScreen
                        style={{width: 600, height: 450, border: 0 }}
                        loading="lazy"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2317.3284519666468!2d-8.4469459252408!3d40.54444004785864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2309a0a9aa8a87%3A0xdaaa09a47ca91e39!2sGrupo%20MAFIROL!5e1!3m2!1spt-PT!2spt!4v1703087899014!5m2!1spt-PT!2spt" 
                        >
                        </iframe>                       
                      </LoadScript>
                    </Col>
                  </Row>
                  {/* <Row className="mt-4">
                    <Col>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        onChange={(event) => {
                          setSelected(
                            locations.find(
                              (l) => l.id.toString() === event.target.value
                            )
                          );
                          setCurrentLocation(null);
                          mapRef?.setZoom(14);
                        }}
                      >
                        <option value="" disabled>
                          {t(`${contactForm}departmentLocations`, {
                            ns: NameSpace.COMMON,
                          })}
                        </option>
                        {locations.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                  </Row> */}
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </article>
    </>
  );
};

export default Contact;

import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { NameSpace } from "../../data/AppLanguage";
import Util from "../../utils/Util";
import createTable from "./createtable";
import CustomerDataSheetService from "../../services/api/CustomerDataSheet";

import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import VetorBack from "../../assets/images/VetorBack.png";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Box, Button, CircularProgress, Container, IconButton, Typography } from "@mui/material";

import DataSheetModelResponse from "../../models/DataSheetModelResponse";
import ProductModelResponse from "../../models/ProductModelResponse";

import MediaViewer from "../../components/MediaViewer/MediaViewer";
import { useDataSheet } from "../../context/ContactSpecific";
import ProductAccessory from "../../components/ProductAccessory/ProductAccessory";
import ContactSpecificForm from "../../components/ContactSpecificForm/ContactSpecificForm";
import DataSheetPDfGenerator from '../../components/DataSheet-JsPdfGenerator/DataSheetPDFGenerator';
import DataSheetModelResponseToPDF from "../../models/DataSheetPDF/DataSheetModelResponseToPDF";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductVariantFinal.css";
import style from './PDFGenerator.module.css';
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import Gamma from "../../services/api/Gamma";
import Category from "../../services/api/Category";
import { FamilyModel } from "../../models/FamilyModel";


interface Settings {
    dots: boolean,
    speed: number,
    slidesToShow: number,
    slidesToScroll: number,
    infinite: boolean,
    autoplay: boolean,
    autoplaySpeed: number,
    prevArrow: any,
    nextArrow: any,
    responsive: any
}

const ProductVariantFinal = () => {
    const params = useParams<{gamma: string; line: string; family: string; subfamily: string;}>();    

    const [productsAccessories, setProductsAccessories] = useState<ProductModelResponse[]>([]);
    const [dataSheet, setDataSheet] = useState<DataSheetModelResponse | undefined>(undefined);
    const { t, i18n } = useTranslation([NameSpace.ROUTES, NameSpace.NAVIGATION]);
    const language = Util.splitLanguage(i18n.language, 0);
    
    const history = useHistory();
    const { setDataSheetData } = useDataSheet();
    const contactFormRef = useRef<HTMLElement>(null);  
    const accessoryProductsRef = useRef<HTMLElement>(null);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
    const [imageSelected, setImageSelected] = useState<string>(dataSheet?.urlImgMain ?? "");

    // Visualização do item
    const [visible, setVisible] = useState(false);
    const [ activeImageIndex, setActiveImageIndex] = useState(0);

    const [gammaName, setGammaName] = useState<string>("");
    const [lineName, setLineName] = useState<string>("");
    const [familyName, setFamilyName] = useState<string>("");
    const [familyResult, setFamilyResult] = useState<FamilyModel>();        

    
    useEffect(() => {
      Gamma.getGammas(language).then((res) => {
          var result = res.data;
          const gammaNameResult = result.find(item => item.path === params.gamma)?.name;
          setGammaName(gammaNameResult ?? "");
      })

      if(gammaName !== ""){
          Category.getLineFamilies(params.gamma, params.line, language).then((res) => {                
              var result = res.data;
              setLineName(result.current.name);
              const familyNameResult = result.successors.find(item => item.path === params.family)?.name;
              setFamilyName(familyNameResult ?? "");
          })
      }
  }, [gammaName]);



  useEffect(() => {
      if(params.gamma && params.line && params.family) {
          Category.getFamily(
            params.gamma,
            params.line,
            params.family,
              language
          ).then((res) => {
              setFamilyResult(res.data);
              // console.log('a família que veio com as subfamílias é: ', res.data);
          }).catch((error) => error);
      }
  },[params.gamma, params.line, params.family]);

    const askInformation = () => {
      if (contactFormRef.current) {
        contactFormRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    };
  
    const scrollToAccessoryProducts = () => {
      if(accessoryProductsRef.current)
      {
        accessoryProductsRef.current.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }

    useEffect(() => {
      setIsSmallScreen(window.innerWidth < 1200); // Verifique se a largura da tela é menor que 1200px ao carregar a página
      window.addEventListener("resize", () => {
          setIsSmallScreen(window.innerWidth < 1200); // Atualize o estado quando a tela for redimensionada
      });

      return () => {
          window.removeEventListener("resize", () => {});
      };
    }, []);


    useEffect(() => {
        if(params.subfamily !== null && params.subfamily !== undefined){
            CustomerDataSheetService.getCustomerDataSheetById(params.subfamily, language).then((res) => {
              const result = res.data;
                setDataSheet(res.data);              

                const image = result?.urlImgMain ?? "";
                setImageSelected(image);

                setDataSheetData(result);
            })                        
        }
    },[params.subfamily])

    useEffect(() => {
        if(dataSheet && dataSheet.dataTable)
        {
            createTable(dataSheet?.dataTable, language.toUpperCase()); 
        }
    }, [dataSheet?.dataTable])

    useEffect(() => {
      if(dataSheet?.code != null && dataSheet.code != "")
      {
        CustomerDataSheetService.getProductsByDataSheetCode(dataSheet.code, language).then((res) => {
          setProductsAccessories(res.data);

        }).catch(err => console.log(err))
      }
    },[dataSheet])

    const handleImageChange = (url?: string, colorId?: number) => {
      const urlMedia = dataSheet?.customDataSheetColorMedia?.find((item) => item.dataSheetColorID == colorId)?.urlCustomDataSheetMedia;
      if(urlMedia) setImageSelected(urlMedia);
      if(url) setImageSelected(url);
    };
    
    const NextArrow = ({ onClick }: { onClick: () => void }) => (
      <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', right: -25, transform: 'translateY(-50%)' }}>
        <MdKeyboardArrowRight />
      </IconButton>
    );
  
    const PrevArrow = ({ onClick }: { onClick: () => void }) => (
      <IconButton onClick={onClick} size="large" sx={{ position: 'absolute', top: '50%', left: -25, transform: 'translateY(-50%)' }}>
        <MdKeyboardArrowLeft />
      </IconButton>
    );

    const settings: Settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 1000,
        nextArrow: <NextArrow />,
      
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
  
    const openImageViewer = () => {
       let index = 0;
       if(imageSelected === dataSheet?.urlImgCut)
       {
        index = 1;
       }
      
       setActiveImageIndex(index);
       setVisible(true);
    };

    const closeImageViewer = () => {
      setVisible(false);
    }

    const backLevelPage = () => {
      history.push(`/${language}/${params.gamma}/${params.line}/${params.family}`);
    }

    const generatePdf = async () => {
      if(dataSheet)
        {
          setIsGeneratingPDF(true);

          await DataSheetPDfGenerator(dataSheet, language);
          
          setIsGeneratingPDF(false);

        }
    };


    return(
      <>
      <Container maxWidth={"1500px"}  sx={{ maxWidth: "1500px" , height: "auto" , paddingTop: 20}}>    
          <BreadcrumbPath
              ancestors={[
              {
                  name: gammaName,
                  path: params.gamma,
              },
              {
                  name: lineName,
                  path: params.line,
              },
              {
                  name: familyName,
                  path: params.family,
              },
              ]}
              className="pt-1"
              current={dataSheet?.title ?? ""}                                                    
          />  
      
          <Box className="contentProduct" width={'100%'}>
          
            <div
              className="mb-3"
              style={{ cursor: "pointer" , marginTop: '-50px', position: 'absolute'}} 
              onClick={() => backLevelPage()}    
            >
              <> 
                <img src={VetorBack} alt=""  width={30}/>
              </>
            </div>
              {!isSmallScreen && 
                <Box display="flex" flexDirection="column" className="thumbnailContainer" >
                  {
                      dataSheet?.code ? 
                      (
                        <Typography 
                        fontSize={11} 
                        align="left"
                        sx={{ color: "#878787", textTransform: "none !important", pl: 1, }}
                        >
                            Ref: {dataSheet?.code} 
                        </Typography>
                      ) : null
                    }
                   {dataSheet?.urlImgMain && 
                      <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgMain ?? "")} >
                          <img src={dataSheet?.urlImgMain} alt="image main"  className="thumbnailImage" />
                      </div>
                   }
                   
                    {dataSheet?.urlImgCut && 
                      <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgCut ?? "")}>
                          <img src={dataSheet?.urlImgCut} alt="image cut" className="thumbnailImage" />
                      </div>                    
                    }                    
                    <Typography fontSize={11} sx={{ color: '#878787', mt:1 }}>* {t("nonContractualImage", { ns: NameSpace.NAVIGATION})}</Typography>                   
                </Box>
              }


                <Box className="product-image" display={'block'}>
                  <Box display={''} alignItems={"center"}>
                    {/* <Box className="image-disclaimer" style={{ writingMode: 'vertical-rl', textOrientation: 'mixed'}} sx={{ transform: "rotate(180deg)", maxWidth: "5%"}}>
                      <Typography fontSize={11} sx={{ color: '#878787' }}>* Imagem não contratual</Typography>
                    </Box> */}
                    <img 
                      src={imageSelected}
                      alt=""
                      style={{ maxWidth: "95%", cursor: "pointer"}}
                      onClick={() => openImageViewer()}
                    />                  
                    {isSmallScreen && 
                    <Typography fontSize={11} sx={{ color: '#878787', mt:1, ml:5, pl:5 }}>* {t("nonContractualImage", { ns: NameSpace.NAVIGATION})}</Typography>                   
                    
                    }
                  </Box>                   
                     {isSmallScreen && 
                        <Box display="flex" className="thumbnailContainer" style={{flexDirection: 'row', paddingTop: '20px', justifyContent: 'center'}}>
                          {dataSheet?.urlImgMain && 
                              <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgMain ?? "", undefined)} >
                                  <img src={dataSheet?.urlImgMain} alt="image main"  className="thumbnailImage" />
                              </div>
                          }
                          
                            {dataSheet?.urlImgCut && 
                              <div  className={'thumbnail'} onClick={() => handleImageChange(dataSheet?.urlImgCut ?? "", undefined)}>
                                  <img src={dataSheet?.urlImgCut} alt="image cut" className="thumbnailImage" />
                              </div>                    
                            }
                        </Box>
                      }
                     <div className={'cardContainer'}>
                    {dataSheet?.listColor1 != 0 && dataSheet?.listColor1 && 
                    <>
                    {/* <h5 className={'cardTitle'}>{dataSheet?.ExternColorsDescription}Cores externas</h5>
                    <hr className={'hr'}/> */}
                    <div className="row">
                            {dataSheet?.listColorWithMedia && dataSheet?.listColorWithMedia.map((item, index) => {
                                return(
                                    <div  className={'cardColor'}>
                                        <img 
                                        key={index}
                                        className={'imageColor'}
                                        src={item.url} alt="image"
                                        style={{ cursor: true === true ? 'pointer' : 'none' }}
                                        onClick={() => handleImageChange("", item.id)} //item . imagem atribuída a cor
                                        />
                                    </div>
                                );
                            
                            })}                            
                    </div>
                    </>                             
                    }
                
                </div>
                </Box>
                <Box className="product-info" display={'block'}>
                    <Box className="product-header">
                            <Typography 
                                className="title"
                                component={'h1'} 
                                variant="h1" 
                                sx={{ 
                                  textTransform: "uppercase" ,
                                  color: "#28487A", 
                                  fontSize: "35px",
                                  fontWeight: 700
                                }}
                            >
                                {dataSheet?.title}
                            </Typography>
                            <Typography component={'h2'} variant="body2" sx={{ textTransform: "uppercase" , color: "#8B939F", mb: 2}}>
                              {dataSheet?.subtitle}
                            </Typography>
                            <Typography component={'p'} variant="body2" sx={{ fontSize: "15px"}}>
                               {dataSheet?.subtitleText}    
                            </Typography>
                    </Box>
                    <Box className="product-body" sx={{ paddingBottom: "2em" }}>
                            <Typography 
                                className="subtitle"
                                component={'h2'} 
                                variant="h2" 
                                sx={{ 
                                    textTransform: "uppercase" ,
                                    color: "#345C8D", 
                                    fontSize: "30px",
                                    fontWeight: 400,
                                    paddingTop: "1.8em"
                                }}
                            >
                                {t("productInformation", { ns: NameSpace.NAVIGATION})}
                            </Typography>
                            <Box className="list-options pt-4">                            
                                <span dangerouslySetInnerHTML={{ __html: dataSheet?.paragraph1 }}></span>
                            </Box>
                    </Box>               
                    <Box 
                        sx={{ borderTop: "1px solid #000000", paddingTop: "5px", justifyContent: 'space-between', textAlign: 'justify' }}
                    >
                            <Button 
                            startIcon={isGeneratingPDF === false ? <FileDownloadOutlinedIcon sx={{ color: "#000000"}}/> : <CircularProgress />} 
                            sx={{ mx: 2 }}
                            onClick={() => generatePdf()}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                   {t("dataSheets", {ns: NameSpace.PAGES })}
                                </Typography>                            
                            </Button> 
                            {/* <Button 
                            startIcon={isGeneratingPDF === false ? <FileDownloadOutlinedIcon sx={{ color: "#000000"}}/> : <CircularProgress />} 
                            sx={{ mx: 2 }}
                            onClick={() => printDataSheet()}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                   {t("dataSheets", {ns: NameSpace.PAGES })}
                                </Typography>                            
                            </Button>  */}
                            {/* <Button 
                            startIcon={isGeneratingPDF === false ? <lumina.DownloadIcon /> : <CircularProgress />} 
                            sx={{ mx: 2 }}
                            onClick={() => generatePdf()}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                   {t("dataSheets", {ns: NameSpace.PAGES })}
                                </Typography>                            
                            </Button> */}
                            { 
                              productsAccessories.length > 0 ? (
                              <Button 
                               endIcon={<Chip label={productsAccessories.length}  sx={{ color: "#ffffff", backgroundColor: "#345C8D",  }}/>} 
                               sx={{ mx: 2 }}
                               onClick={() => scrollToAccessoryProducts()}
                               >
                                   <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                       {t("accessory", { ns: NameSpace.NAVIGATION})}
                                   </Typography>   
                              </Button> 
                              ) : null
                               
                            }
                          

                            <Button 
                            startIcon={<AddIcon sx={{ color: "#000000"}}/>}
                            onClick={() => askInformation()} 
                            sx={{ mx: 2 }}
                            >
                                <Typography sx={{ color: "#000000", ":hover" : { color: "#345C8D" } }}>
                                    {t("knowMore", { ns: NameSpace.NAVIGATION })}
                                </Typography>   
                            </Button>                            
                    </Box>
                </Box>
          </Box>           
          
            <Typography 
              className="subtitle"
              component={'h2'} 
              variant="h2" 
              align="center"
              sx={{ 
                  textTransform: "uppercase" ,
                  color: "#345C8D", 
                  fontSize: "30px",
                  fontWeight: 400,
                  paddingTop: "1.8em"
              }}
            >
                {t("technicalInformation", { ns: NameSpace.NAVIGATION})}        
            </Typography>          
            <hr style={{ margin: '10px 0 50px 0' }}/>
            
          
           <Box
            sx={{ width: '100%', marginTop: '50px !important', justifyContent: 'center', textAlign: 'center', display: 'flex', mb: 1}}
           >
                <div 
                    className={`${style.content_table} ${style.backgroundImageColor} table_container`}
                >
                    <div id="tableId" 
                    className={`${style.table} ${style.backgroundImageColor}`}
                    ></div>                               
                </div>                 
           </Box>
           <Box
                sx={{ width: '100%', marginTop: '0px !important', justifyContent: 'center', textAlign: 'center', display: 'flex', mb: 5}}
           >
            {
              dataSheet?.technicalInformation && 
           
              <Typography fontSize={11}>
                * {dataSheet?.technicalInformation}
              </Typography>  
               }          
           </Box>


          <Box id="acessory" ref={accessoryProductsRef}></Box>
           {
             productsAccessories.length ? (
          <Box className="content" sx={{ mt: "25px"}}>   
            <Typography 
              className="subtitle"
              component={'h2'} 
              variant="h2" 
              align="center"
              sx={{ 
                  textTransform: "uppercase" ,
                  color: "#345C8D", 
                  fontSize: "30px",
                  fontWeight: 400,
                  paddingTop: "30px"
              }}
            >
              {t("accessories", { ns: NameSpace.NAVIGATION})}                            
            </Typography>                 
               
            <hr style={{ margin: '2px 0 50px 0' }} />
            {/* <p ref={accessoryProductsRef}></p> */}
            <ProductAccessory products={productsAccessories} />
               
          </Box>
          ) : null
          } 
        </Container>
        <ContactSpecificForm
              // dataSheetProduct={dataSheet}
              className="mt-10"
              ref={contactFormRef}
          /> 
        <MediaViewer
          visible={visible}
          onClose={closeImageViewer}
          activeIndex={activeImageIndex}
          images={[
            { src: dataSheet?.urlImgMain ?? ""},
            { src: dataSheet?.urlImgCut ?? "" }
          ]}
        />        
      </>
        
    );

};

export default ProductVariantFinal;


  
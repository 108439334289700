// import style from './PDFGenerator.module.css'


// export default function createTable(data, language) {
//     // Crie uma tabela HTML
//     const table = document.createElement('table');

//     if(data && data.length == 0){
//         const existingElement = document.getElementById('tableId');
//         existingElement.remove();
//     }

//     table.style.width = "100%";
//     table.style.height = "185px";
//     // table.style.minWidth = '895px';
//     // table.style.marginLeft = '-150px';
//     // Preencha os cabeçalhos da tabela com modelos únicos em ordem crescente
//     const uniqueModels = [...new Set(data.map(item => item.feature.model))];
//     uniqueModels.sort((a, b) => a - b); // Ordenar os modelos em ordem crescente numérica

//     // Crie uma linha de cabeçalho
//     const headerRow = document.createElement('tr');

//     // Mapeamento das línguas para as palavras-chave correspondentes
//     const languageToKeyword = {
//         "PT": "MODELO",
//         "EN": "MODEL",
//         "FR": "MODÈLE",
//         "ES": "MODELO",
//     };

//     // Obtenha a palavra-chave com base na língua fornecida
//     const languageResult = languageToKeyword[language] || "Model"; // Usará "Model" como padrão se a língua não for encontrada


//     // Adicione uma célula vazia para a parte superior esquerda da tabela
//     const emptyHeaderCell = document.createElement('th');
//     emptyHeaderCell.textContent = `${languageResult}`;    
//     emptyHeaderCell.classList.add(style.greentext);
//     emptyHeaderCell.style.textAlign = 'right'
//     headerRow.appendChild(emptyHeaderCell);

//     uniqueModels.forEach(model => {
//         const headerCell = document.createElement('th');
//         headerCell.textContent = `${model}`;
//         //Styles
//         headerCell.style.border = '6px'
//         headerCell.style.padding = '2px'
//         headerCell.style.borderStyle = 'solid'
//         headerCell.style.borderColor = 'white'
//         headerCell.style.borderli = 'solid'
//         headerCell.style.textAlign = 'center'

//         headerCell.classList.add(style.tableHeader);
//         headerRow.appendChild(headerCell);
//     });

//     // Adicione a linha de cabeçalho à tabela
//     table.appendChild(headerRow);

//     // Crie um objeto para mapear as traduções para os valores correspondentes
//     const translationToValueMap = {};

//     // Preencha o mapeamento das traduções para os valores

//     data.sort((a, b) => a.feature.position - b.feature.position);

//     data.forEach(item => {
//         const translation = item.translations[0]?.name; // Supomos que as traduções sejam únicas
//         const model = item.feature.model;
//         const value = item.feature.value;

//         if (!translationToValueMap[translation]) {
//             translationToValueMap[translation] = {};
//         }

//         translationToValueMap[translation][model] = value;
//     });

//     // Preencha as linhas da tabela com traduções e valores
//     for (const translation in translationToValueMap) {
//         // Verifique se a tradução não é undefined, vazia ou nula antes de criar a linha
//         if (translation !== null && translation !== '' && translation !== 'undefined' && translation !== 'NaN') {
//             const translationRow = document.createElement('tr');

//             translationRow.style.textAlign = 'right'
//             translationRow.style.border = '6px'
//             translationRow.style.padding = '2px'
//             translationRow.style.borderStyle = 'solid'
//             translationRow.style.borderColor = 'white'
//             translationRow.style.borderli = 'solid'

//             // Alternar entre as classes de cores "gray-row" e "white-row"
//             if (table.rows.length % 2 === 0) {
//                 translationRow.classList.add(style.white_row);
//             } else {
//                 translationRow.classList.add(style.gray_row);
//             }

//             // Crie uma célula para a tradução
//             const translationCell = document.createElement('td');
//             translationCell.textContent = translation;
//             translationRow.appendChild(translationCell);

//             const valueToUrlMap = {
//                 "A.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172351808_f900b70d-817b-43aa-8b82-d9bb76a8606e.jpg",
//                 "B.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172408158_0df5639c-c310-4603-b566-bd1d796fa1a7.jpg",
//                 "C.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172425079_fba4ffcb-cef0-4dc3-bb8c-b324f41e0f9d.jpg",
//                 "D.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172437494_1af8e266-236e-4fa6-a497-a7b9cc524d1a.jpg",
//                 "E.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172449586_4c99e082-15f7-482f-a749-91abe1ccdbd2.jpg",
//                 "F.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172503150_f8c2824f-9291-4005-89cf-ead2ba5c52c7.jpg",
//                 "G.jpg": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20230313141543304_a7c37510-06e3-4c1c-8081-6af15c9d809c.jpg",

//                 "A": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172351808_f900b70d-817b-43aa-8b82-d9bb76a8606e.jpg",
//                 "B": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172408158_0df5639c-c310-4603-b566-bd1d796fa1a7.jpg",
//                 "C": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172425079_fba4ffcb-cef0-4dc3-bb8c-b324f41e0f9d.jpg",
//                 "D": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172437494_1af8e266-236e-4fa6-a497-a7b9cc524d1a.jpg",
//                 "E": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172449586_4c99e082-15f7-482f-a749-91abe1ccdbd2.jpg",
//                 "F": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20220107172503150_f8c2824f-9291-4005-89cf-ead2ba5c52c7.jpg",
//                 "G": "https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/20230313141543304_a7c37510-06e3-4c1c-8081-6af15c9d809c.jpg",
//             }


//             uniqueModels.forEach(model => {
//                 const value = translationToValueMap[translation][model] || '-'; // Use uma string vazia se não houver valor
//                 const valueCell = document.createElement('td');
//                 // Styles
//                 valueCell.style.textAlign = 'center'
//                 valueCell.style.border = '6px'
//                 valueCell.style.borderStyle = 'solid'
//                 valueCell.style.borderColor = 'white'
//                 valueCell.style.borderli = 'solid'
                
//                 // valueCell.style.minWidth = '120px';
                

//                 const item = data.find(item => item.translations[0]?.name === translation && item.feature.model === model);
            
//                 if (item && item.feature.id.includes("Mir-06") && value) {
//                             // Verifique se o valor está no mapeamento
//                     if (valueToUrlMap.hasOwnProperty(value)) {
//                         // Crie um elemento de imagem e defina o atributo src com base no URL mapeado
//                         const imageElement = document.createElement('img');
//                         imageElement.src = valueToUrlMap[value];
//                         imageElement.width = 60;
//                         // Adicione a imagem à célula
//                         valueCell.appendChild(imageElement);
//                         valueCell.textAlign = 'center';
//                     } else {
//                         // Se o valor não estiver no mapeamento, defina o texto normal
//                         valueCell.textAlign = 'center';
//                         valueCell.textContent = value;
//                     }
//                 }
//                 else if (item && item.feature.id.includes("Mec-06") && value) {
//                     // Verifique se o valor está no mapeamento
//                     if (valueToUrlMap.hasOwnProperty(value)) {
//                         // Crie um elemento de imagem e defina o atributo src com base no URL mapeado
//                         const imageElement = document.createElement('img');
//                         imageElement.src = valueToUrlMap[value];
//                         imageElement.width = 60;
//                         // Adicione a imagem à célula
//                         valueCell.appendChild(imageElement);
//                         valueCell.textAlign = 'center';
//                     } else {
//                         // Se o valor não estiver no mapeamento, defina o texto normal
//                         valueCell.textAlign = 'center';
//                         valueCell.textContent = value;
//                     }
//                 } 
//                 else {
//                     // Se não for "06" ou o valor estiver vazio, apenas defina o texto normal
//                     valueCell.textContent = value;
//                 }
            
//                 translationRow.appendChild(valueCell);
//             });


//             // Adicione a linha de tradução à tabela
//             table.appendChild(translationRow);
//         }
//     }

//     // Adicione a tabela ao seu elemento de destino (substitua 'elementId' pelo ID do elemento HTML onde deseja inserir a tabela)
//     const tableContainer = document.getElementById('tableId');
//     if (tableContainer != null) {
//         tableContainer.appendChild(table);
//     }
// }

import style from './PDFGenerator.module.css';

export default function createTable(data, language) {
    // Crie uma tabela HTML
    const table = document.createElement('table');
    table.classList.add(style.responsiveTable);

    if (data && data.length === 0) {
        const existingElement = document.getElementById('tableId');
        if (existingElement) {
            existingElement.remove();
        }
    }

    // Preencha os cabeçalhos da tabela com modelos únicos em ordem crescente
    const uniqueModels = [...new Set(data.map(item => item.feature.model))];
    uniqueModels.sort((a, b) => a - b); // Ordenar os modelos em ordem crescente numérica

    // Crie uma linha de cabeçalho
    const headerRow = document.createElement('tr');

    // Mapeamento das línguas para as palavras-chave correspondentes
    const languageToKeyword = {
        "PT": "MODELO",
        "EN": "MODEL",
        "FR": "MODÈLE",
        "ES": "MODELO",
    };

    // Obtenha a palavra-chave com base na língua fornecida
    const languageResult = languageToKeyword[language] || "Model"; // Usará "Model" como padrão se a língua não for encontrada

    // Adicione uma célula vazia para a parte superior esquerda da tabela
    const emptyHeaderCell = document.createElement('th');
    emptyHeaderCell.textContent = languageResult;
    emptyHeaderCell.classList.add(style.greentext);
    emptyHeaderCell.style.textAlign = 'center';
    emptyHeaderCell.style.fontSize = '15px';
    headerRow.appendChild(emptyHeaderCell);

    uniqueModels.forEach(model => {
        const headerCell = document.createElement('th');
        headerCell.textContent = model;
        headerCell.style.textAlign = 'center';
        headerCell.style.fontSize = '15px';
        //Styles
        headerCell.classList.add(style.tableHeader);
        headerRow.appendChild(headerCell);
    });

    // Adicione a linha de cabeçalho à tabela
    table.appendChild(headerRow);

    // Crie um conjunto para manter o controle das traduções já adicionadas
    const addedTranslations = new Set();

    // Preencha as linhas da tabela com traduções e valores
    data.forEach(item => {
        const translation = item.translations[0]?.name; // Supomos que as traduções sejam únicas

        // Verifique se esta tradução já foi adicionada à tabela
        if (!addedTranslations.has(translation)) {
            const translationRow = document.createElement('tr');
           
            // Crie uma célula para a tradução
            const translationCell = document.createElement('td');
            translationCell.style.fontSize = '15px';
            translationCell.textContent = translation;
            translationRow.appendChild(translationCell);

            uniqueModels.forEach(model => {
                const matchingItem = data.find(d => d.translations[0]?.name === translation && d.feature.model === model);
                let value = matchingItem ? matchingItem.feature.value || '-' : '-';

                const valueCell = document.createElement('td');
                valueCell.style.textAlign = 'center';
                valueCell.style.fontSize = '15px';

                // Verifique se é uma condição para adicionar uma imagem
                if (matchingItem && (matchingItem.feature.id.includes("Mec-06") || matchingItem.feature.id.includes("Mir-06"))) {
                    // value = value.toUpperCase(); // Converte para maiúsculas para corresponder às letras
                    const imageElement = document.createElement('img');
                    imageElement.src = `https://mmsirius.siriusmaf.pt/Categories/Subfamilies/EnergyEfficiency/${value}`;
                    imageElement.width = 60;
                    valueCell.appendChild(imageElement);
                } else {
                    valueCell.textContent = value;
                }
                translationRow.appendChild(valueCell);
            });

            // Adicione a linha de tradução à tabela
            table.appendChild(translationRow);

            // Adicione esta tradução ao conjunto de traduções já adicionadas
            addedTranslations.add(translation);
        }
    });

    // Remova a tabela antiga se existir
    const tableContainer = document.getElementById('tableId');
    if (tableContainer) {
        tableContainer.innerHTML = '';
    }

    // Adicione a nova tabela ao contêiner
    if (tableContainer) {
        tableContainer.appendChild(table);
    }
}

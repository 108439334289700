import { string } from "yup";
import { destroy, get, post, put } from "./Api";
import CustomerDataSheetByFamilyResponse from "../../models/CustomerDataSheetByFamilyResponse";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";
import ProductAcessory from "../../models/ProductModelResponse";

const CustomerDataSheetService = {    
    getCustomerDataSheetById: async (id: string, language: string) => 
            get<DataSheetModelResponse>(`customerdatasheet/customerdatasheetbyid/${id}/${language}`),    

    getCustomerDataSheetsByFamily: async (id: string, language: string) => 
            get<CustomerDataSheetByFamilyResponse[]>(`customerdatasheet/customerdatasheetfamily/${id}/${language}`),


    getProductsByDataSheetCode: async (dataSheetCode: string, language: string) =>
            get<ProductAcessory[]>(`product/getproductbydatasheetcode/${dataSheetCode}/${language}`),
            
};

export default CustomerDataSheetService;
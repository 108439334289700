import { forwardRef, Ref, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Row, Col, Button, Form, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { FamilyModel } from "../../models/FamilyModel";
import { ContactSpecificFormModel } from "../../models/ContactSpecificFormModel";
import { CountryModel } from "../../models/CountryModel";

import ContactSpecificFormSchema from "../../schemas/ContactSpecificFormSchema";

import {
  NameSpace,
  contactForm,
  specificForm,
  privacyPolicy,
  buttons,
} from "../../data/AppLanguage";

import Util from "../../utils/Util";

import ContactService from "../../services/api/Contact";
import CountryService from "../../services/api/Country";

import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import SpinnerAnimation from "../SpinnerAnimation/SpinnerAnimation";
import DataSheetModelResponse from "../../models/DataSheetModelResponse";
import { useDataSheet } from "../../context/ContactSpecific";


interface Props {
  // dataSheetResponse?: DataSheetModelResponse;
  galleryId?: number;
  className?: string;
}

const ContactSpecificForm = forwardRef(
  (props: Props, ref: Ref<HTMLElement>) => {
    const [submitClicked, setSubmitClicked] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [countries, setCountries] = useState<CountryModel[]>([]);
    const { dataSheetData } = useDataSheet();

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
      control,
    } = useForm<ContactSpecificFormModel>({
      resolver: yupResolver(ContactSpecificFormSchema()),
    });

    const { t, i18n } = useTranslation([
      NameSpace.COMMON,
      NameSpace.GLOSSARY,
      NameSpace.ROUTES,
    ]);
    const language = Util.splitLanguage(i18n.language, 0);

    useEffect(() => {
      let mounted = true;

      if (mounted) {
        CountryService.getCountries().then((res) => {
          setCountries(res.data);
        });

        if (formSubmitted) {
          setTimeout(() => {
            setFormSubmitted(false);
            setSubmitClicked(0);
            reset({
              phoneNumber: Util.getCountryCode(
                Util.splitLanguage(i18n.language, 1).toLowerCase()
              ),
            });
          }, 1500);
        }
      }

      return () => {
        mounted = false;
      };
    }, [formSubmitted, i18n.language, reset, dataSheetData]);

    const onSubmit: SubmitHandler<ContactSpecificFormModel> = (data) => {
      setSubmitting(true);
      data.phoneNumber = `+${data.phoneNumber}`;
      data.customDataSheetId = dataSheetData?.id;
      data.dataSheetCode = dataSheetData?.code;
      data.productUrl = window.location.href;
      data.productName = `${dataSheetData?.title} - ${dataSheetData?.subtitle}`;

      if (dataSheetData) {
        ContactService.postContactSpecificCategories(
          data,
          // Number(props.dataSheetResponse?.ancestors[0].id),
          dataSheetData?.id,
          dataSheetData?.code
        )
          .then(() => {
            setSubmitting(false);
            setFormSubmitted(true);
          })
          .catch(() => {
            setSubmitting(false);
          });
      } 
      // else if (props.galleryId) {
      //   ContactService.postContactSpecificSolution(data, props.galleryId)
      //     .then(() => {
      //       setSubmitting(false);
      //       setFormSubmitted(true);
      //     })
      //     .catch(() => {
      //       setSubmitting(false);
      //     });
      // }
    };

    return (
      <article
        className={`contact-form pt-5 ${props.className}`}
        ref={ref}
        style={{ paddingBottom: '6rem'}}
      >
        <Container className="contact-form-wrapper">
          <Row>
            <Col>
              <h2 className="contact-form-wrapper-title">
                <Trans
                  i18nKey={`${specificForm}title`}
                  ns={NameSpace.CONTACTFORMS}
                >
                  Precisa de ajuda?
                </Trans>
              </h2>
              <h5 className="contact-form-wrapper-subtitle">
                <Trans
                  i18nKey={`${specificForm}subtitle`}
                  ns={NameSpace.CONTACTFORMS}
                >
                  Entre em contacto connosco.
                </Trans>
              </h5>
            </Col>
          </Row>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-4">
              <Col xs={12} md={6}>
                <Form.Row>
                  <Form.Group as={Col} controlId="nameInput" xs={12} md={11}>
                    <Form.Label>
                      {t(`${contactForm}name`, { ns: NameSpace.COMMON })}*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t(`${contactForm}name`, {
                        ns: NameSpace.COMMON,
                      })}
                      isValid={submitClicked > 0 && !errors.name}
                      isInvalid={!!errors.name}
                      {...register("name")}
                    />
                    {errors.name && (
                      <Form.Control.Feedback type="invalid">
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="emailInput" xs={12} md={11}>
                    <Form.Label>
                      {t(`${contactForm}email`, { ns: NameSpace.COMMON })}*
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t(`${contactForm}email`, {
                        ns: NameSpace.COMMON,
                      })}
                      isValid={submitClicked > 0 && !errors.email}
                      isInvalid={!!errors.email}
                      {...register("email")}
                    />
                    {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId="phoneNumberInput"
                    xs={12}
                    md={11}
                  >
                    <Form.Label>
                      {t(`${contactForm}phoneNumber`, { ns: NameSpace.COMMON })}
                      *
                    </Form.Label>
                    <PhoneNumberForm
                      control={control}
                      submitClicked={submitClicked}
                      errors={errors.phoneNumber}
                      errorsMessage={errors.phoneNumber?.message}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="countryInput" xs={12} md={11}>
                    <Form.Label>
                      {t(`${contactForm}country`, { ns: NameSpace.COMMON })}*
                    </Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      isValid={submitClicked > 0 && !errors.countryId}
                      isInvalid={!!errors.countryId}
                      {...register("countryId")}
                    >
                      <option value="" disabled>
                        {t(`${contactForm}country`, { ns: NameSpace.COMMON })}
                      </option>
                      {countries.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                    {errors.countryId && (
                      <Form.Control.Feedback type="invalid">
                        {errors.countryId.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    controlId="locationInput"
                    xs={12}
                    md={11}
                  >
                    <Form.Label>
                      {t(`${contactForm}location`, { ns: NameSpace.COMMON })}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t(`${contactForm}location`, {
                        ns: NameSpace.COMMON,
                      })}
                      isValid={submitClicked > 0 && !errors.location}
                      isInvalid={!!errors.location}
                      {...register("location")}
                    />
                    {errors.location && (
                      <Form.Control.Feedback type="invalid">
                        {errors.location.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col xs={12} md={6}>
                <Form.Row className="d-flex justify-content-end">
                  <Form.Group as={Col} controlId="messageInput">
                    <Form.Label>
                      {t(`${contactForm}message`, { ns: NameSpace.COMMON })}*
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={t(`${contactForm}message`, {
                        ns: NameSpace.COMMON,
                      })}
                      rows={14}
                      isValid={submitClicked > 0 && !errors.message}
                      isInvalid={!!errors.message}
                      {...register("message")}
                    />
                    {errors.message && (
                      <Form.Control.Feedback type="invalid">
                        {errors.message.message}
                      </Form.Control.Feedback>
                    )}
                    <small className="contact-form-wrapper-required">
                      * Campos Obrigatórios
                    </small>
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
            <Form.Row className="align-items-center justify-content-end">
              <Col
                md={4}
                xs={12}
                className="contact-form-wrapper-privacy-policy-col"
              >
                <FormCheck>
                  <FormCheck.Input
                    isValid={submitClicked > 0 && !errors.privacyPolicy}
                    isInvalid={!!errors.privacyPolicy}
                    {...register("privacyPolicy")}
                  />
                  <FormCheck.Label>
                    {t(`${privacyPolicy}readAccept`, { ns: NameSpace.COMMON })}{" "}
                    <Link
                      className="contact-form-wrapper-privacy-policy"
                      to={`/${language}/${t("privacyPolicy", {
                        ns: NameSpace.ROUTES,
                      })}`}
                    >
                      {t("privacyPolicy", { ns: NameSpace.GLOSSARY })}
                    </Link>
                    .*
                  </FormCheck.Label>
                  {errors.privacyPolicy && (
                    <Form.Control.Feedback type="invalid">
                      {errors.privacyPolicy.message}
                    </Form.Control.Feedback>
                  )}
                </FormCheck>
              </Col>
              <Col
                xs={12}
                md="auto"
                className="contact-form-wrapper-submit-col"
              >
                {submitting ? (
                  <SpinnerAnimation />
                ) : formSubmitted ? (
                  <p className="contact-form-wrapper-submit-success">
                    {t(`${contactForm}messageSuccess`, {
                      ns: NameSpace.COMMON,
                    })}
                  </p>
                ) : (
                  <Button
                    type="submit"
                    variant="outline-dark"
                    className="contact-form-wrapper-submit"
                    onClick={() => {
                      setSubmitClicked(submitClicked + 1);
                    }}
                  >
                    {t(`${buttons}submit`, { ns: NameSpace.COMMON })}
                  </Button>
                )}
              </Col>
            </Form.Row>
          </Form>
        </Container>
      </article>
    );
  }
);

export default ContactSpecificForm;

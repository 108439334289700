import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CategoryItemModel } from "../../models/CategoryItemModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./BreadcrumbPath.css";

interface Props {
  ancestors?: CategoryItemModel[];
  current?: string;
  className?: string;
}

const BreadcrumbPath = (props: Props) => {
  const { t, i18n } = useTranslation(NameSpace.NAVIGATION);
  const language = Util.splitLanguage(i18n.language, 0);

  const ancestors = props.ancestors?.map((item) => {
    return item.path;
  });

  const buildUrl = (index: number) => {
    return `/${language}/${ancestors?.slice(0, index + 1)?.join("/")}`;
  };

  return (
    <article className={`pb-3 ${props.className}`}>
      <Container className="breadcrumb-wrapper">
        <Row>
          <Col className="pl-0">
            <Breadcrumb>
              <Breadcrumb.Item href={`/${language}`}>
                {t("home")}
              </Breadcrumb.Item>
              {props.ancestors?.map((item, index) => {
                return (
                  <Breadcrumb.Item key={index} href={buildUrl(index)}>
                    {item.name}
                  </Breadcrumb.Item>
                );
              })}
              <Breadcrumb.Item active>{props.current}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default BreadcrumbPath;

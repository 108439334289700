import { Container, Row, Col } from "react-bootstrap";

import { GammaModel } from "../../models/GammaModel";

import GammaItem from "../GammaItem/GammaItem";

import "./Gamma.css";
import GammaCardItem from "../Cards/GammaCardItem/GammaCardItem";
import { useTranslation } from "react-i18next";
import Util from "../../utils/Util";
import { Grid } from "@mui/material";

interface Props {
  gammas: GammaModel[];
}

const Gamma = (props: Props) => {

  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  const filteredGammas = props.gammas.filter((item) => 
    !(item.path.toLowerCase().includes("comercial") || item.path.toLocaleLowerCase().includes("commercial"))
      || !["fr", "es"].includes(language.toLocaleLowerCase())
  );


  return (
    <article className="mt-5 pt-5 pb-5">
      <Grid container  sx={{ justifyContent: "center"}}>
        {/* <Row className="justify-content-center"> */}
          {filteredGammas.map((item, index) => {
            return (             
              <Grid item
                xl={3}
                lg={4}
                md={6}
                sm={12}
                key={index}
                className="d-flex justify-content-center"
                marginBottom={5}
              >
                <GammaItem gamma={item} />
                {/* <GammaCardItem gamma={item} /> */}
              </Grid>
            );
          })}
        {/* </Row> */}
      </Grid>
    </article>
  );
};

export default Gamma;
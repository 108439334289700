import { addMethod, string, mixed } from "yup";
import { getName } from "country-list";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { saveAs } from "file-saver";
import mime from "mime-types";
import i18n from "i18next";

import {
  jobApplicationExtension,
  jobApplicationMaxSize,
} from "../data/JobApplicationExtension";
import {
  NameSpace,
  countriesCodes,
  fallbackCountryCode,
} from "../data/AppLanguage";

import DownloadService from "../services/api/Download";

const app = "SIRIUS";

addMethod(string, "validPhoneNumber", (errorMessage) => {
  return string().test("test-phone-number", errorMessage, function (value) {
    const { path, createError } = this;

    if (value?.trim() === "")
      return createError({
        path,
        message: i18n.t("required", { ns: NameSpace.VALIDATION }),
      });

    return (
      isPossiblePhoneNumber(`+${value}` || "") ||
      createError({ path, message: errorMessage })
    );
  });
});

addMethod(string, "validCountryId", (errorMessage) => {
  return string().test("test-country-id", errorMessage, function (value) {
    const { path, createError } = this;

    if (value?.trim() === "")
      return createError({
        path,
        message: i18n.t("required", { ns: NameSpace.VALIDATION }),
      });

    var country = getName(value || "");

    return !!country || createError({ path, message: errorMessage });
  });
});

addMethod(string, "validDateOfBirth", (errorMessage) => {
  return string().test("test-date-of-birth", errorMessage, function (value) {
    const { path, createError } = this;

    if (!value)
      return createError({
        path,
        message: i18n.t("required", { ns: NameSpace.VALIDATION }),
      });

    var today = new Date();
    var date = new Date(value || "");
    var age = today.getFullYear() - date.getFullYear();
    var m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) age--;

    return age >= 18 || createError({ path, message: errorMessage });
  });
});

addMethod(
  mixed,
  "validCurriculumVitae",
  (errorMaxSizeMessage, errorExtensionMessage) => {
    return mixed().test(
      "test-curriculum-vitae",
      { errorMaxSizeMessage, errorExtensionMessage },
      function (value) {
        const { path, createError } = this;

        if (!value || value?.length === 0)
          return createError({
            path,
            message: i18n.t("required", { ns: NameSpace.VALIDATION }),
          });

        if (value[0].size > jobApplicationMaxSize)
          return createError({ path, message: errorMaxSizeMessage });

        var extension = value[0].name
          .slice(((value[0].name.lastIndexOf(".") - 1) >>> 0) + 2)
          .toLowerCase();

        if (!jobApplicationExtension.includes(extension))
          return createError({ path, message: errorExtensionMessage });

        return true;
      }
    );
  }
);

const Util = {
  setDocumentTitle: (title: string) => `${title} - ${app}`,

  setCategoryDocumentTitle: (items: string[], current?: string) =>
    `${items.join(" | ")} ${current ? `| ${current}` : ""} - ${app}`,

  getCategoryPath: (gamma?: string, line?: string, family?: string) =>
    `/${gamma}/${line}/${family}`,

  getFamilyDocument: (familyId: string, documentId: number) => {
    DownloadService.downloadDocument(familyId, documentId).then((res) => {
      if (res.data) {
        var url = URL.createObjectURL(res.data);
        window.open(url, "_blank", "noopener, noreferrer");
      }
    });
  },

  downloadFamilyDocument: (
    familyId: string,
    documentId: number,
    familyPath: string,
    language: string
  ) => {
    DownloadService.downloadDocument(familyId, documentId).then((res) => {
      if (res.data)
        saveAs(
          res.data,
          `${familyPath}_${language.toUpperCase()}.${mime.extension(
            res.data.type
          )}`
        );
    });
  },

  splitLanguage: (language: string, index: number) => {
    var l =
      language.includes("-") && language.split("-")[index]
        ? language.split("-")[index]
        : language;
    return l;
  },

  joinLanguage: (language: string, countryCode: string) =>
    `${language.toLowerCase()}-${countryCode.toLowerCase()}`,

  getCountryCode: (countryCode: string) =>
    countriesCodes[countryCode] !== undefined
      ? countriesCodes[countryCode]
      : fallbackCountryCode,

  getRoutesPath: (routes?: string[]) =>
    routes
      ? routes
          .map((item) => `${i18n.t(item, { ns: NameSpace.ROUTES })}`)
          .join("/")
      : "",
};

export default Util;

import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image /* Collapse, Button */,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GammaModel } from "../../models/GammaModel";

import { NameSpace } from "../../data/AppLanguage";

import Util from "../../utils/Util";

import "./GammaItem.css";
import { Box, Card, Typography } from "@mui/material";

interface Props {
  gamma: GammaModel;
}

const GammaItem = (props: Props) => {
  const [hover, setHover] = useState(false);
  const changeHover = () => setHover(!hover);

  const { i18n } = useTranslation([NameSpace.COMMON]);
  const language = Util.splitLanguage(i18n.language, 0);


  return (
    <Box paddingX={"25px"}>
      <Row
      style={{ minHeight: "100%"}}
      >
        <Col className="p-0">
          <Link to={`/${language}/${props.gamma.path}`}
          >           
            <Card
            style={{ minHeight : "100%" }}
            >
              <div className="menu-option animation"
                id={`${props.gamma.path}`}
                style={{
                padding: "3em 2em",
                position: "relative",
                display: "inline-block",
                cursor: "pointer",
                overflow: "hidden"
              }}
              >
                <div className="menu-option-content "
                >                       
                  <img
                    className="image"
                    src={props.gamma.mediaSmall?.path}
                    alt={props.gamma.name}
                    title="image"
                    style={{width: "6em", height: "6em"}}
                  />
                  <div className="title">
                    <Typography component={'h1'} variant="h1" 
                    style={{fontWeight: "bold", color: "rgb(64, 67, 70)", fontSize: "22px", fontFamily: 'Roboto', textTransform: 'uppercase',
                    marginTop: "15px", marginBottom: "15px"
                  }}
                    >
                        {props.gamma.name}
                    </Typography>
                  </div>
                  <div className="description">
                    <Typography
                    align="left"
                    style={{ fontSize: "15px", color: "rgb(106, 106, 106)", textAlign: "justify"}}
                    >
                      {props.gamma.description === null ? "" : props.gamma.description}
                    </Typography>
                  </div>                      
                </div>
              </div> 
                </Card>
          </Link>
        </Col>
      </Row>    
    </Box>
  );
};

export default GammaItem;

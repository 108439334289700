import { ContactFormModel } from "../../models/ContactFormModel";
import { ContactPhoneFormModel } from "../../models/ContactPhoneFormModel";
import { ContactGenericFormModel } from "../../models/ContactGenericFormModel";
import { ContactSpecificFormModel } from "../../models/ContactSpecificFormModel";
import { LocationModel } from "../../models/LocationModel";

import { get, post } from "./Api";

const endpoint = "/contact/";

const Contact = {
  getLocations: async (language: string) =>
    get<LocationModel[]>(`${endpoint}locations`, {
      params: { language },
    }),

  postContact: async (contact: ContactFormModel, userId?: number) =>
    post(`${endpoint}contact`, contact, {
      params: { userId },
    }),

  postContactPhone: async (
    countryCode: string,
    contactPhone: ContactPhoneFormModel,
    userId?: number
  ) =>
    post(`${endpoint}contactphone`, contactPhone, {
      params: { userId, countryCode },
    }),

  postContactGeneric: async (
    contactGeneric: ContactGenericFormModel,
    userId?: number
  ) =>
    post(`${endpoint}contactgeneric`, contactGeneric, {
      params: userId,
    }),

  postContactSpecificCategories: async (
    contactSpecific: ContactSpecificFormModel,
    // gammaId?: number,
    cutsomDataSheetId?: string,
    dataSheetCode?: string,
    userId?: number
  ) =>
    post(
      `${endpoint}contactspecificcategory/${cutsomDataSheetId}/${dataSheetCode}`,
      contactSpecific,
      { params: userId }
    ),

  postContactSpecificSolution: async (
    contactSpecific: ContactSpecificFormModel,
    galleryId: number,
    userId?: number
  ) =>
    post(`${endpoint}contactspecificsolution/${galleryId}`, contactSpecific, {
      params: userId,
    }),
};

export default Contact;

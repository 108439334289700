import { SetStateAction, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Row, Col, Button, Form, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { ContactGenericFormModel } from "../../models/ContactGenericFormModel";
import { CountryModel } from "../../models/CountryModel";
import { DepartmentModel } from "../../models/DepartmentModel";

import ContactGenericFormSchema from "../../schemas/ContactGenericFormSchema";

import {
  NameSpace,
  genericForm,
  contactForm,
  buttons,
  privacyPolicy,
} from "../../data/AppLanguage";

import Util from "../../utils/Util";

import ContactService from "../../services/api/Contact";
import DepartmentService from "../../services/api/Department";
import CountryService from "../../services/api/Country";
import emailjs from '@emailjs/browser'

import PhoneNumberForm from "../PhoneNumberForm/PhoneNumberForm";
import SpinnerAnimation from "../SpinnerAnimation/SpinnerAnimation";

interface Props {
  className?: string;
}

const ContactGenericForm = (props: Props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setTelefone] = useState('')
  const [countryId, setPais] = useState('')
  const [location, setLocalidade] = useState('')
  const [company, setEmpresa] = useState('')
  const [departmentId, setDepartamento] = useState('')
  const [message, setMessage] = useState('')
  const [submitClicked, setSubmitClicked] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [departments, setDepartments] = useState<DepartmentModel[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<ContactGenericFormModel>({
    resolver: yupResolver(ContactGenericFormSchema(departments)),
  });

  const { t, i18n } = useTranslation([
    NameSpace.COMMON,
    NameSpace.GLOSSARY,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      DepartmentService.getDepartments(language).then((res) => {
        setDepartments(res.data);
      });
      CountryService.getCountries().then((res) => {
        setCountries(res.data);
      });

      if (formSubmitted) {
        setTimeout(() => {
          setFormSubmitted(false);
          setSubmitClicked(0);
          reset({
            phoneNumber: Util.getCountryCode(
              Util.splitLanguage(i18n.language, 1).toLowerCase()
            ),
          });
        }, 1500);
      }
    }

    return () => {
      mounted = false;
    };
  }, [language, formSubmitted, i18n.language, reset]);

  const onSubmit: SubmitHandler<ContactGenericFormModel> = (data) => {
    setSubmitting(true);
    data.phoneNumber = `+${data.phoneNumber}`;

    ContactService.postContactGeneric(data)
      .then(() => {
        setSubmitting(false);
        setFormSubmitted(true);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <article className={`contact-form pt-5 ${props.className}`} style={{ paddingBottom: '6rem'}}>
      <Container className="contact-form-wrapper">
        <Row>
          <Col>
            <h2 className="contact-form-wrapper-title">
              <Trans
                i18nKey={`${genericForm}title`}
                ns={NameSpace.CONTACTFORMS}
              >
                Diga-nos <br /> o que procura.
              </Trans>
            </h2>
            <h5 className="contact-form-wrapper-subtitle">
              <Trans
                i18nKey={`${genericForm}subtitle`}
                ns={NameSpace.CONTACTFORMS}
              >
                Temos as melhores soluções e equipamentos para o seu espaço
                comercial!
              </Trans>
            </h5>
          </Col>
        </Row>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={11} 
                controlId="nameInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setName(e.target.value)} value={name} >
                  <Form.Label>
                    {t(`${contactForm}name`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}name`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.name}
                    isInvalid={!!errors.name}
                    {...register("name")}
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid">
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={11} 
                controlId="emailInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmail(e.target.value)} value={email}>
                  <Form.Label>
                    {t(`${contactForm}email`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={t(`${contactForm}email`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.email}
                    isInvalid={!!errors.email}
                    {...register("email")}
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="phoneNumberInput"
                  onChange={(e: { target: { value: SetStateAction<string>; }; }) => setTelefone(e.target.value)} value={phoneNumber}
                  xs={12}
                  md={11}
                >
                  <Form.Label>
                    {t(`${contactForm}phoneNumber`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <PhoneNumberForm
                    control={control}
                    submitClicked={submitClicked}
                    errors={errors.phoneNumber}
                    errorsMessage={errors.phoneNumber?.message}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={11} 
                controlId="countryInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPais(e.target.value)} value={countryId}>
                  <Form.Label>
                    {t(`${contactForm}country`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue=""
                    isValid={submitClicked > 0 && !errors.countryId}
                    isInvalid={!!errors.countryId}
                    {...register("countryId")}
                  >
                    <option value="" disabled>
                      {t(`${contactForm}country`, { ns: NameSpace.COMMON })}
                    </option>
                    {countries.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.countryId && (
                    <Form.Control.Feedback type="invalid">
                      {errors.countryId.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={11} 
                controlId="locationInput" 
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLocalidade(e.target.value)} value={location}>
                  <Form.Label>
                    {t(`${contactForm}location`, { ns: NameSpace.COMMON })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}location`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.location}
                    isInvalid={!!errors.location}
                    {...register("location")}
                  />
                  {errors.location && (
                    <Form.Control.Feedback type="invalid">
                      {errors.location.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
            </Col>
            <Col xs={12} md={6}>
              <Form.Row className="d-flex justify-content-end">
                <Form.Group as={Col} 
                controlId="companyInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmpresa(e.target.value)} value={company}>
                  <Form.Label>
                    {t(`${contactForm}company`, { ns: NameSpace.COMMON })}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t(`${contactForm}company`, {
                      ns: NameSpace.COMMON,
                    })}
                    isValid={submitClicked > 0 && !errors.company}
                    isInvalid={!!errors.company}
                    {...register("company")}
                  />
                  {errors.company && (
                    <Form.Control.Feedback type="invalid">
                      {errors.company.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row className="d-flex justify-content-end">
                <Form.Group as={Col} 
                controlId="departmentIdInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setDepartamento(e.target.value)} value={departmentId}>
                  <Form.Label>
                    {t(`${contactForm}department`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue=""
                    isValid={submitClicked > 0 && !errors.departmentId}
                    isInvalid={!!errors.departmentId}
                    {...register("departmentId")}
                  >
                    <option value="" disabled>
                      {t(`${contactForm}department`, { ns: NameSpace.COMMON })}
                    </option>
                    {departments.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {errors.departmentId && (
                    <Form.Control.Feedback type="invalid">
                      {errors.departmentId.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row className="d-flex justify-content-end">
                <Form.Group as={Col} 
                controlId="messageInput"
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setMessage(e.target.value)} value={message}>
                  <Form.Label>
                    {t(`${contactForm}message`, { ns: NameSpace.COMMON })}*
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder={t(`${contactForm}message`, {
                      ns: NameSpace.COMMON,
                    })}
                    rows={9}
                    isValid={submitClicked > 0 && !errors.message}
                    isInvalid={!!errors.message}
                    {...register("message")}
                  />
                  {errors.message && (
                    <Form.Control.Feedback type="invalid">
                      {errors.message.message}
                    </Form.Control.Feedback>
                  )}
                  <small className="contact-form-wrapper-required">
                    {t(`${contactForm}required`, { ns: NameSpace.COMMON })}
                  </small>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
          <Form.Row className="align-items-center justify-content-end">
            <Col
              md={4}
              xs={12}
              className="contact-form-wrapper-privacy-policy-col"
            >
              <FormCheck>
                <FormCheck.Input
                  isValid={submitClicked > 0 && !errors.privacyPolicy}
                  isInvalid={!!errors.privacyPolicy}
                  {...register("privacyPolicy")}
                />
                <FormCheck.Label>
                  {t(`${privacyPolicy}readAccept`, { ns: NameSpace.COMMON })}{" "}
                  <Link
                    className="contact-form-wrapper-privacy-policy"
                    to={`/${language}/${t("privacyPolicy", {
                      ns: NameSpace.ROUTES,
                    })}`}
                  >
                    {t("privacyPolicy", { ns: NameSpace.GLOSSARY })}
                  </Link>
                  .*
                </FormCheck.Label>
                {errors.privacyPolicy && (
                  <Form.Control.Feedback type="invalid">
                    {errors.privacyPolicy.message}
                  </Form.Control.Feedback>
                )}
              </FormCheck>
            </Col>
            <Col xs={12} md="auto" className="contact-form-wrapper-submit-col">
              {submitting ? (
                <SpinnerAnimation />
              ) : formSubmitted ? (
                <p className="contact-form-wrapper-submit-success">
                  {t(`${contactForm}messageSuccess`, { ns: NameSpace.COMMON })}
                </p>
              ) : (
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="contact-form-wrapper-submit"
                  onClick={() => {
                    setSubmitClicked(submitClicked + 1);
                  }}
                >
                  {t(`${buttons}submit`, { ns: NameSpace.COMMON })}
                </Button>
              )}
            </Col>
          </Form.Row>
        </Form>
      </Container>
    </article>
  );
};

export default ContactGenericForm;



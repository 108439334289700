import React, { createContext, useContext, useState } from "react";
import DataSheetModelResponse from "../models/DataSheetModelResponse";
import OpportunityGammaModel from "../models/OpportunityGammaModel";


//################### CONTEXTO DAS OPORTUNIDADES #############################################################

const OpportunityContext = createContext<any>({});

export const ThemeProvider = ({children}: any) => {
    const [opportunittyByGamma, setOpporunittyByGamma] = useState<any>();
 
    return (
        <OpportunityContext.Provider value={{opportunittyByGamma, setOpporunittyByGamma}}>
            {children}
        </OpportunityContext.Provider>
    );
}


//################### CONTEXTO DOS DADOS DE CONTACTOS PARA AS FICHAS ########################################


// Definindo o tipo para o contexto
// interface DataSheetContextType {
//     dataSheetData : DataSheetModelResponse | undefined;
//     setDataSheetData: (data: DataSheetModelResponse | undefined) => void;
// }


// // Criando o contexto 
// const DataSheetContext = createContext<DataSheetContextType | undefined>(undefined);


// // Criando um gancho personalizado para acessar o contexto 7 hook para facilitar o uso do context
// export const useDataSheet = () => {
//     const context = useContext(DataSheetContext);
//     if(!context) {
//         throw new Error('useDataSheet deve ser usado dentro de um DataSheetProvider');
//     }

//     return context;
// };

// // Criando um componente de provedor para fornecer o contexto
// export const DataSheetProvider: React.FC = ({children}) => {
//     const [dataSheetData, setDataSheetData] = useState<DataSheetModelResponse | undefined>(undefined);

//     return(
//         <DataSheetContext.Provider value={{ dataSheetData, setDataSheetData}}>
//             {children}
//         </DataSheetContext.Provider>
//     );
// } ;





export {
    OpportunityContext,    
    // DataSheetContext,
}
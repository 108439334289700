import { Container, Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CategoryModel } from "../../models/CategoryModel";

import { NameSpace } from "../../data/AppLanguage";

import CategoryItem from "../CategoryItem/CategoryItem";

import "./Category.css";
import CategoryItemCommercial from "../CategoryItemCommercial/CategoryItemCommercial";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";




interface Props {
  category?: CategoryModel;
  nameClassName?: string;
  borderBottom?: boolean;
  overlay?: boolean;
  scaleImage?: boolean;
  background?: boolean;
  isCommercial?: boolean; 
}

const Category = (props: Props) => {
  const { t } = useTranslation([NameSpace.PAGES]);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);


  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 1300); // Verifique se a largura da tela é menor que 1200px ao carregar a página
    window.addEventListener("resize", () => {
        setIsSmallScreen(window.innerWidth < 1300); // Atualize o estado quando a tela for redimensionada
    });

    return () => {
        window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <article className="mb-8">
      <Box display={"flex"} sx={{ justifyContent: "center"}}>
        {/* <Row className="category-wrapper"> */}
          {props.category?.successors.length && !props.isCommercial ? (
            <Grid container
            width={"100%"}
            spacing={1}
            justifyContent={'center'}
            columns={12}
            sx={{ padding: "0 10px 0 19px" }}
            >
              {props.category?.successors.map((item, index) => {
                return (
                  <Grid
                    item
                    // xs={12}
                    sm={6}
                    md={3}                      
                    lg={isSmallScreen ? 3 : 2}
                    // xl={3}
                    key={index}
                    className="mb-5 mx-3"
                    sx={{ 
                      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      borderRadius: "4px",
                      boxShadow: "3px -3px 1px -3px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                      
                    }}
                    
                    
                  >
                    <CategoryItem
                      category={props.category}
                      primaryMedia={item.media}
                      name={item.name}
                      path={item.path}
                      style={{
                        nameClassName: props.nameClassName,
                        overlay: props.overlay,
                        scaleImage: props.scaleImage,
                        background: props.background,
                      }}
                    />
                  </Grid>
                );
            })}
            </Grid>
           
          ) : !props.isCommercial && (
            <Col>
              <Alert variant="warning" className="category-wrapper-no-content">
                {t("noContent")}
              </Alert>
            </Col>
          )}


          {/* Se for comercial renderizar esse componete item */}

          {props.category?.successors.length && props.isCommercial ? (
            <Grid container
            spacing={4}
            columns={12}
            justifyContent={"center"}
            sx={{ padding: isSmallScreen ? "0 60px 0 60px" : "0 150px 0 150px" }}
            > 
              {props.category?.successors.map((item, index) => {
                  return (
                    <Grid item
                      xs={12}
                      md={4}                      
                      lg={2.5}
                      xl={2}
                      key={index}
                      className="category-wrapper-col"    
                      display={"flex"}
                      justifyContent={"center"} 
                      sx={{ 
                        // transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        // borderRadius: "4px",
                        boxShadow: "3px -3px 1px -3px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                        // marginX: "auto",
                        padding: "0px !important",
                        
                        // mb: 4,
                        margin: "0px 15px 30px 15px",
                       }}                  
                    >
                      <CategoryItemCommercial
                        category={props.category}
                        primaryMedia={item.media}
                        name={item.name}                    
                        path={item.path}
                        style={{
                          cardClassName: "mx-auto",
                          nameClassName: props.nameClassName,                      
                          overlay: props.overlay,
                          scaleImage: props.scaleImage,
                          background: props.background,
                        }}
                      />
                    </Grid>
                  );
                })
              }
            </Grid>
          ) : props.isCommercial && (
            <Col>
              <Alert variant="warning" className="category-wrapper-no-content">
                {t("noContent")}
              </Alert>
            </Col>
          )}
        {/* </Row> */}
      </Box>
    </article>
  );
};

export default Category;

import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, Image } from "react-bootstrap";
/* Commented temporarily */
/* import { BiSearchAlt2 } from "react-icons/bi";
import { FiUser } from "react-icons/fi"; */
import Hamburger from "hamburger-react";
import { IoLocationSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import { MenuSubNavModel } from "../../models/MenuSubNavModel";

import Util from "../../utils/Util";

import { mediumWidth } from "../../data/BreakpointsWidth";

import navbarLogo from "../../assets/images/logo_name_blue.svg";

import { NameSpace } from "../../data/AppLanguage";

import LanguageSwitcher from "../../components/LanguageSwitcher/LanguageSwitcher";

import "./NavigationBar.css";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  windowWidth: number;
  mobileSubNav: MenuSubNavModel | null;
}



const NavigationBar = (props: Props) => {
  const { t, i18n } = useTranslation([NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);

  return (
    <>
      {props.windowWidth >= mediumWidth || !props.open ? (
        <Navbar
          fixed="top"
          className={`navigation-bar ${
            props.open ? "navigation-bar-white" : ""
          } `}
        >
          <Navbar className="w-100">
            <Nav className="navbar-menu">
              <Hamburger toggled={props.open} toggle={props.setOpen} />
            </Nav>
          </Navbar>
          <Navbar.Brand
            className="mx-auto"
            as={Link}
            to="/"
            onClick={() => props.setOpen(false)}
          >
            <Image src={navbarLogo} alt="Sirius" className="navbar-logo" />
          </Navbar.Brand>
          <Navbar className="w-100">
            <Nav className="ml-auto">
              {/* Commented temporarily */}
              {/* <Nav.Link>
                <BiSearchAlt2 className="navbar-search" />
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                to={`/${language}/${t("contact")}`}
                onClick={() => props.setOpen(false)}
                className="navbar-location"
              >
                <IoLocationSharp className="navbar-location-icon" />
              </Nav.Link>
              {/* Commented temporarily */}
              {/* <Nav.Link className="navbar-user">
                <FiUser className="navbar-user-icon" />
              </Nav.Link> */}
              <LanguageSwitcher />
            </Nav>
          </Navbar>
        </Navbar>
      ) : null}
    </>
  );
};

export default NavigationBar;

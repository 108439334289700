import { MenuLeftModel } from "../models/MenuLeftModel";

export const menuGammasName = "equipments";

export const menuLeftData = (equipments?: boolean) => {
  var data: MenuLeftModel[] = [
    {
      name: "group",
      subNav: [
        {
          name: "aboutUs",
          routes: ["aboutUs"],
        },
        {
          name: "qualityCertification",
          routes: ["qualityCertification"],
        }       
      ],
    },    
    // {
    //   name: "downloadArea",
    //   routes: ["downloadArea"],
    // },   
    {
      name: "contact",
      routes: ["contact"],
    },
  ];

  if (equipments) data.unshift({ name: menuGammasName, subNav: [] });

  return data;
};

import { useEffect, useState } from "react";
import { match } from "react-router";
import { useTranslation } from "react-i18next";

import { CategoryModel } from "../../models/CategoryModel";

import Util from "../../utils/Util";

import CategoryService from "../../services/api/Category";

import MainTitle from "../../components/MainTitle/MainTitle";
import ContactPhoneForm from "../../components/ContactPhoneForm/ContactPhoneForm";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import CategoryComponent from "../../components/Category/Category";

interface Path {
  gamma: string;
  line: string;
  family: string;
}

interface Props {
  match: match<Path>;
}

const Category = (props: Props) => {
  const [category, setCategory] = useState<CategoryModel>();
  const [backgroundStyle, setBackgroundStyle] = useState(true);
  const params = props.match.params;
  const [isCommercial, setIsCommercial] = useState<boolean>(false);


  const  verifyIsCommercial = (param: string): boolean =>{

    const termosComerciais = [ 'comercial', 'commercial'];

    return termosComerciais.includes(param.toLocaleLowerCase());
  }
  
  

  const { i18n } = useTranslation();
  const language = Util.splitLanguage(i18n.language, 0);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (params.line !== undefined) {
        CategoryService.getLineFamilies(
          params.gamma,
          params.line,
          language
        ).then((res) => {
          document.title = Util.setCategoryDocumentTitle(
            res.data.ancestors.map((a) => a.name),
            res.data.current.name
          );
          setCategory(res.data);
          setBackgroundStyle(true);
        });

        setIsCommercial(verifyIsCommercial(params.gamma));

      } else {
        CategoryService.getGammaLines(params.gamma, language).then((res) => {
          document.title = Util.setDocumentTitle(res.data.current.name);
          setCategory(res.data);
          setBackgroundStyle(false);
        });

        setIsCommercial(verifyIsCommercial(params.gamma));
      }
    }

    return () => {
      mounted = false;
    };
  }, [props.match, params, language]);

  return (
    <>
      <MainTitle
        media={category?.current.media}
        name={category?.current.name}
        height="70vh"
      />
      {/* <ContactPhoneForm /> */}
      <BreadcrumbPath
        ancestors={category?.ancestors}
        current={category?.current.name}
      />
      <CategoryComponent
        category={category}
        nameClassName="text-left text-uppercase"
        borderBottom
        overlay
        scaleImage
        background={backgroundStyle}
        isCommercial={isCommercial}
      />
    </>
  );
};

export default Category;

import { CategoryModel } from "../../models/CategoryModel";
import { FamilyModel } from "../../models/FamilyModel";

import { get } from "./Api";

const endpoint = "/categories/";

const Category = {
  getGammaLines: async (gammaPath: string, language: string) =>
    get<CategoryModel>(`${endpoint}gammalines/${gammaPath}`, {
      params: { language },
    }),

  getLineFamilies: async (
    gammaPath: string,
    linePath: string,
    language: string
  ) =>
    get<CategoryModel>(`${endpoint}linefamilies/${gammaPath}/${linePath}`, {
      params: { language },
    }),

  getFamily: async (
    gammaPath: string,
    linePath: string,
    familyPath: string,
    language: string
  ) =>
    get<FamilyModel>(
      `${endpoint}family/${gammaPath}/${linePath}/${familyPath}`,
      { params: { language } }
    ),
  getSubFamily: async (subfamilyId: string, language: string) => 
    get<CategoryModel>(`${endpoint}subfamily/${subfamilyId}/${language}`),

  getFamilyDocument: async (
    familyId: string,
    documentTypeId: number,
    language: string
  ) =>
    get<Blob>(`${endpoint}familydocument/${familyId}/${documentTypeId}`, {
      params: { language },
      responseType: "blob",
    }),
     
};

export default Category;
